import React from 'react';
import * as jwt_decode from 'jwt-decode';
import SuperAdminEmailIDs from './SuperAdminEmailIDs.json'

export const RoleCheck = () => {
    const tokenFromStorage = localStorage.getItem("msal.idtoken");
    let idToken = "", EmailID = "";
    if (tokenFromStorage)
        idToken = jwt_decode(tokenFromStorage);

    if (idToken != "")
        EmailID = idToken.emails[0];

    if (EmailID != "") {
        let IsExists = SuperAdminEmailIDs.Email.length == 0;
        SuperAdminEmailIDs.Email.forEach((data, Index) => {
            if (data.toLowerCase() == EmailID.toLowerCase()) {
                return IsExists = true;
            }
        });
        return IsExists;
    }
    else
        return false;
}