import React from 'react';
import axiosInstance from '../Shared/Interceptor/interceptor'
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import appConfig from '../../Environment/environments';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import '../../Assests/Styles/Styles.scss';
import Typography from '@material-ui/core/Typography';
import BlackEdit from '../../Assests/Images/edit-black.svg';
import { GETGROUPAPPDETAILS, AppsStyles, APPSLABELTEXT, APPSNOTFOUNDTEXT, APPSLOADINGTEXT, ADDAPP, ADDEDITAPP } from '../../Common/Constants/constants'
import RightDrawer from '../Shared/RightDrawer/RightDrawer';
import AppDetails from './AppAddEdit/AppDetails';
import { setGlobalAdmin } from '../../redux/Actions/globalAdminAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const styles = theme => AppsStyles

class Apps extends React.Component {

  state = { appLists: [], flag: false, loading: true, isGlobalAdmin: false, open: false};
  componentDidMount() {
      this.getAllApps();
  }

  getAllApps() {
    loadProgressBar({ easing: 'ease-in-out', speed: 100,  showSpinner: false }, axiosInstance)
    axiosInstance.get(`${appConfig.api.development}/${GETGROUPAPPDETAILS}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        const {appLists, isGlobalAdmin} = res.data;
        this.props.setGlobalAdmin(isGlobalAdmin);
        (appLists.length > 0) ? this.setState({ appLists, loading: false, isGlobalAdmin: isGlobalAdmin }) : this.setState({ flag: true, isGlobalAdmin: isGlobalAdmin });
      })
  }

  addAnApp = (e) => {
    this.toggleDrawer(e, true)
  }

  routeTo(appURL) {
    const idToken = localStorage.getItem("msal.idtoken");
    window.open(`${appURL}?umtoken=${idToken}`)
  }

  toggleDrawer = (event, closeDrawer) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({
      open: closeDrawer
    })
  };

  appEditClick = (id) => {
    this.props.history.push({
      pathname: ADDEDITAPP,
      id: id,
      type: 'edit'
    })
  }

  appLabelComponent = (classes) => {
    return (
    <>
      <Typography gutterBottom classes={{ root: classes.appsListTitle }}>
        {APPSLABELTEXT}
        {(this.state.isGlobalAdmin) && <Button variant="contained" onClick={(e) => this.addAnApp(e)} disableRipple color="primary" classes={{ root: classes.addAppButton, label: classes.addAppButtonLabel }}>
                {ADDAPP}
            </Button>}
      </Typography>
      <RightDrawer open={this.state.open}>
              <AppDetails onClose={(e) => this.toggleDrawer(e, false)} type="add"/>
      </RightDrawer>
    </>
    )
  }

  render() {
    const { classes } = this.props;
    if (this.state.flag) {
      return (
        <div className = "AppsListContainer">
          {this.appLabelComponent(classes)}
          <Typography gutterBottom classes={{ root: classes.appsListNotFound }}>{APPSNOTFOUNDTEXT}</Typography>
        </div>
      ) 
    }
    else {
      return (
        <div>
          <div className = "AppsListContainer">
            {this.appLabelComponent(classes)}
            {<Grid container spacing={3} classes={{ container: classes.gridContainer }}>
              {
                (this.state.loading) ?  <Typography gutterBottom classes={{ root: classes.appsListNotFound }}>{APPSLOADINGTEXT}</Typography> : this.state.appLists.map((apps,index) =>
                  <Grid  item xs={12} sm={6} md={3} lg={2} zeroMinWidth key={index} classes={{ item: classes.appLevelItem }}>
                    <Card tabIndex="0" className={classes.card}>
                      <div className="EditAppContainer">
                        <CardHeader
                          classes={{
                            root: classes.cardHeader,
                            title: classes.appTitle,
                          }}
                          title={apps.name}
                          onClick={() => this.routeTo(apps.url)}
                        />
                        {(apps.isAppAdmin || this.state.isGlobalAdmin) && <div className="EditAppIconContainer" onClick={() => this.appEditClick(apps.id)}>
                          <img width="12" height="12" className="AppEditIcon" src={BlackEdit} xs={2} />
                        </div>}
                      </div>
                      <CardContent className={classes.cardContent}>
                        <CardMedia
                          className={classes.media}
                          onClick={() => this.routeTo(apps.url)}
                          component="img"
                          image={`data:image/${apps.logoImageType};base64,${apps.logo}`}
                          title={apps.logoImageName} />
                      </CardContent>
                    </Card>
                  </Grid>
                )
              }
            </Grid>
            }
          </div>
        </div>
      )
    }
  }
}

Apps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(null, {setGlobalAdmin})(withStyles(styles)(Apps)));
