import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ReactComponent as EditGroupClose } from '../../../Assests/Images/EditGroupClose.svg';
import { TableHeaderStyles, ADMINGRIDLABELTEXT, LASTSIGNEDINTEXT, USERSGRIDLABELTEXT, ACTIVE } from '../../../Common/Constants/constants';
import { ReactComponent as ExternalUser } from '../../../Assests/Images/User.svg';
import CheckIcon from '@material-ui/icons/Check';

const IOSSwitch = withStyles(TableHeaderStyles)(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

function TableHeader(props) {
  return (
    <div className="table-head"> 
        <div className="table-td">
          <span className="icon-left xs-hide cursor-default">
              {<ExternalUser />}
          </span>
          <span className="icon-left xs-hide cursor-default">
            <CheckIcon/>
          </span>
            {(props.gridType === 0) ? ADMINGRIDLABELTEXT : USERSGRIDLABELTEXT}
        </div>
        <div className="table-td xs-hide">
            {LASTSIGNEDINTEXT}
        </div>
        <div className="table-td">
            <FormControlLabel
                disabled 
                control={
                <IOSSwitch
                    checked={true}
                    value="checkedB"
                />
                }
                label={ACTIVE}
            /> 
        </div>
        <div className="table-td">
        {props.type==='group' ? <MoreVertIcon /> :  <EditGroupClose />
           }
        </div>
    </div>   
  );
}

export default TableHeader;
