import React from 'react';
import 'axios-progress-bar/dist/nprogress.css';
import '../../Assests/Styles/Styles.scss';
import GroupService from './Groups.services';
import BlackEdit from '../../Assests/Images/edit-black.svg';
import Globe from '../../Assests/Images/Globe.svg';
import User from '../../Assests/Images/user-demo.svg';
import Admin from '../../Assests/Images/Admin.svg';
import Group from './Group';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import ToastMessages from '../Shared/Toast/ToastMessages';
import RightDrawer from '../Shared/RightDrawer/RightDrawer';
import AddGroup from './addGroup/addGroup';
import EditGroup from './edit-group/edit-group';
import { LOADINGTEXT, ADDAGROUP, GROUPSTEXT, NOGROUPSCREATED, NAME } from '../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";
import ChangeGroup from './changeGroup/changeGroup';
import { injectIntlTranslation } from "../../Common/Utilities/utility";
import {RoleCheck} from '../Shared/Role/RoleCheck'

class Groups extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      Group: [],
      value: 1,
      statusMessage: false,
      message: "",
      toastVPosition: 'top',
      toastHPosition: 'center',
      openParallelDrawer: false,
      parentSelectionData: {},
      offset: "0px",
      right: false,
      fullScreen: false,
      messageVariant: '',
      editGroupId: 0,
      loading: true,
      reloadGroups: false
    }
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.ToBeExpandedGroups = this.ToBeExpandedGroups.bind(this);
    this.expandedGroups = (this.props.location != undefined && this.props.location.expandedGroups) ? this.props.location.expandedGroups : [];
  }
  
  componentDidMount() {
    this.fetchGroups();
  }

  fetchGroups = () => {
    GroupService.getAllGroups((result) => {
      if(result.length > 0)
      {
        this.setState({
          Group: result,
          loading: false,
          reloadGroups: false
        });
      }
      else {
        window.location.reload();
      }
    })
  }

  ToBeExpandedGroups(shouldAdd, id){
    let index = this.expandedGroups.indexOf(id);
    if (shouldAdd) { 
      (index <= -1) && this.expandedGroups.push(id);
    }
    else { 
      (index > -1) && this.expandedGroups.splice(index, this.expandedGroups.length - index);
    }
  }
  DisplayRootGroup() {
    if (this.state.Group.length > 0) {
      return (
        this.state.Group.map((ChildGroup, index) => (
          <Group TreeLevel={0} key={index} Group={ChildGroup} toBeExpandedGroups={this.ToBeExpandedGroups} expandedGroups={this.expandedGroups} setEditGroupId={this.setEditGroupId} />
        ))
      )
    }
  }
  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      statusMessage: false
    });
  }

  deleteGroupHandler = (statusMessage, message, messageVariant) => {
    this.setState({
      statusMessage: statusMessage,
      message: message,
      messageVariant: messageVariant,
      fullScreen: false,
      Group: [],
      loading: true
    }, () => this.fetchGroups());
  }

  setEditGroupId = (groupId, showEditGroup=true, reloadGroups=false) => {
    let reloadProps = {}
    if (reloadGroups && this.expandedGroups.length > 0) {
      reloadProps = {
        Group: [],
        loading: true
      } 
    }
    this.setState({
      editGroupId: groupId,
      fullScreen: showEditGroup,
      right: false,
      ...reloadProps
    }, () => reloadGroups && this.fetchGroups()) 
  }

  openParallelDrawer = () => this.setState({openParallelDrawer: true, offset: "600px"})

  toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({
      [side]: open,
      parentSelectionData: {}
    })
  };

  closeChangeGroup = () => this.setState({openParallelDrawer: false, offset: "0px"})

  onParentSelectionChange = (parentSelectionData) => {
    this.setState({parentSelectionData: parentSelectionData, openParallelDrawer: false, offset: "0px"})
  }

  render() {
    const { intl } = this.props;
    const {parentSelectionData: {label, value}} = this.state;
    return (
      <>
        <div>
          <div className="mainDiv">
            {RoleCheck() ? <input type="button" value={injectIntlTranslation(intl, ADDAGROUP)} className="addButton" onClick={this.toggleDrawer('right', true)}></input> : ""}
            <p className="h-2-black-bold">
              {GROUPSTEXT}
            </p>
          </div>
          {(!this.state.Group.length > 0) ? 
            <p className="h-2-sub-title">
              {this.state.loading ? LOADINGTEXT : NOGROUPSCREATED}
            </p> 
          :
            <React.Fragment>
              <CssBaseline />
              <Container maxWidth="xl">
                <ul className="GroupsList" style={{ paddingLeft: "0px" }}>
                  <li className="GroupsListHeader">
                    <div className="ExpendedHeaderColumn">{NAME}</div>
                    <div data-tip= {"Edit"} className="HeaderIconColumn_edit"><img className="GroupsTableHeaderIcon" src={BlackEdit} xs={2} /></div>
                    <div data-tip= {"Groups"} className="HeaderIconColumn"><img className="GroupsTableHeaderIcon" src={Globe} xs={2} /></div>
                    <div data-tip={"Administrators"} className="HeaderIconColumn"><img className="GroupsTableHeaderIcon" src={Admin} xs={2} /></div>
                    <div data-tip={"Users"} className="HeaderIconColumn"><img className="GroupsTableHeaderIcon" src={User} xs={2} /></div>
                  </li>
                  {this.DisplayRootGroup()}
                </ul>
              </Container>
              <ToastMessages  statusMessage={this.state.statusMessage} message={this.state.message} variant={this.state.messageVariant} toastHPosition={this.state.toastHPosition} toastVPosition={this.state.toastVPosition} close={this.handleToast.bind(this)} />
              <RightDrawer customProps={{right: this.state.offset}} open={this.state.right}>
                <AddGroup openParallelDrawer={this.openParallelDrawer} selectedItem={this.state.parentSelectionData} setEditGroupId={this.setEditGroupId} expandedGroups={this.expandedGroups} onClose={this.toggleDrawer('right', false)}/>
              </RightDrawer>
              <RightDrawer customProps={{width:this.state.offset}} open={this.state.openParallelDrawer}>
                <ChangeGroup
                  selectedGroupId={value}
                  selectedGroupName={label}
                  callApiOnSave={false}
                  onParentChange={this.onParentSelectionChange}
                  onClose={this.closeChangeGroup}
                />
              </RightDrawer>
              <RightDrawer fullScreen open={this.state.fullScreen}>
                <EditGroup id={this.state.editGroupId} expandedGroups={this.expandedGroups} deleteGroupHandler={this.deleteGroupHandler} onClose={this.setEditGroupId}/>
              </RightDrawer>
            </React.Fragment>
          }
        </div>
      </>
    )      
  }
}

Groups.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(Groups)