import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { makeStyles } from '@material-ui/core/styles';
import { OptionMenuDialogStyles, CONFIRMATIONTEXT, CANCELTEXT } from '../../../Common/Constants/constants';

const useStyles = makeStyles(OptionMenuDialogStyles);

function DialogBox(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.opennow}
        onClose={props.onCloseEvent}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.root }}
      >
        <DialogTitle id="responsive-dialog-title"> {props.dialogHead}</DialogTitle>
        <DialogContent id="addbg">
          <DialogContentText classes={{ root: classes.deletetitle }}>
            {props.dialogContent}
          <br />   <br />
            {(props.dialogDisclaimer) ? props.dialogDisclaimer : CONFIRMATIONTEXT}
          </DialogContentText>
        </DialogContent>
        <DialogActions id="dialogbutton">
          {(props.isDisabled) ?
            <>
            <Button disabled color="secondary" variant="outlined" >
              {props.dialogButtonText}
            </Button>
            <Button disabled color="secondary" variant="outlined" >
            {CANCELTEXT}
            </Button>
            </>
          :
            <>
            <Button onClick={props.onCloseClick} color="primary" variant="outlined" >
              {props.dialogButtonText}
            </Button>
            <Button onClick={props.onCloseEvent} color="primary" variant="outlined" autoFocus>
              {CANCELTEXT}
            </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogBox.propTypes = {
  fullScreen: PropTypes.bool.isRequired,

};

export default withMobileDialog()(DialogBox);