import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {RightDrawerStyles} from './RightDrawerHelper';

export default function RightDrawer(props) {
  const {fullScreen=false, customProps={}, children, open, onClose } = props;
  const {fullScreenGroupDrawerPaper, addGroupDrawerPaper, fullscreenList, list} = RightDrawerStyles(customProps);
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={ fullScreen ? fullscreenList : list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      {children}
    </div>
  );

  return (
    <Drawer
        anchor="right"
        open={(open) ? open : false}
        onClose={onClose}
        PaperProps={{
            classes: {
                root: fullScreen ? fullScreenGroupDrawerPaper : addGroupDrawerPaper
            }
        }}
      >
        {sideList('right')}
      </Drawer>
  );
}