import axios from 'axios';
import promise from 'promise';
import { logout } from '../../../Common/Utilities/utility';

var axiosInstance = axios.create();
axiosInstance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem("msal.idtoken");
  if (accessToken) {
    if (config.method !== 'OPTIONS') {
      config.headers.authorization = 'Bearer ' + accessToken;
    }
  }
  return config;
}, function (error) {

  return promise.reject(error);
});


axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error && error.response && error.response.status === 401) {
    logout();
    return;
  }
  return Promise.reject(error);
});

export default axiosInstance;