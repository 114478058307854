import React from 'react';
import './footer.scss';
import { FormattedMessage as Culture } from 'react-intl';

var hideDivStyle = {
    display: 'none'
};

const Footer = () => {
    return(
        <footer>
            <div className="container" style={{marginLeft:'20%', width:'60%'}}>
                <div className="footer-wrapper">
                    <div className="links">
                        <a 
                            href="https://www.carrier.com/carrier/en/worldwide/legal/privacy-notice/" 
                            rel="noopener noreferrer" 
                            target="_blank"
                        >
                            <Culture id="FooterPrivacyPolicy"/> 
                        </a>
                        <a 
                            href="https://www.carrier.com/carrier/en/worldwide/legal/terms-of-use/" 
                            rel="noopener noreferrer" 
                            target="_blank"
                        >
                            <Culture id="FooterTermsOfUse" />
                        </a>
                        <a 
                            href="https://www.carrier.com/commercial-refrigeration/en/eu/" 
                            rel="noopener noreferrer" 
                            target="_blank"
                        >
                            Carrier Company
                        </a>
                        {/* <a 
                            href="https://www.carrier.com/carrier/en/worldwide/about/?location=us"
                            rel="noopener noreferrer" 
                            target="_blank"
                        >
                            <Culture id="FooterContactUs" />
                        </a> */}
                    </div>
                    <div className="details">
                        <a 
                            href="https://www.corporate.carrier.com/" 
                            rel="noopener noreferrer" 
                            target="_blank"
                            style={hideDivStyle}
                        >
                            <Culture id="CarrierCorpName" />
                        </a>
                        <span style={hideDivStyle}>&nbsp; | &nbsp; </span>
                        <span>
                            Cool Systems Select.
                            &nbsp;&nbsp;&copy;2021&nbsp; Carrier. All Rights Reserved.
                        </span>
                    </div>  
                </div>              
            </div>
        </footer>
    );
}

export default Footer;
