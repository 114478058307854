import deTranslate from './locale/translate-de.json';
import enTranslate from './locale/translate-en.json';
import esTranslate from './locale/translate-es.json';
import frTranslate from './locale/translate-fr.json';
import itTranslate from './locale/translate-it.json';
import nlTranslate from './locale/translate-nl.json';
import plTranslate from './locale/translate-pl.json';
import ruTranslate from './locale/translate-ru.json';
import trTranslate from './locale/translate-tr.json';

export default {
    de: deTranslate,
    en: enTranslate,
    es: esTranslate,
    fr: frTranslate,
    it: itTranslate,
    nl: nlTranslate,
    pl: plTranslate,
    ru: ruTranslate,
    tr: trTranslate
}