import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Regionmesg } from '../../../Assests/Images/Regionmesg.svg';
import { ToastMessageStyle1, ToastMessageStyle2 } from '../../../Common/Constants/constants';
import '../../../Assests/Styles/Styles.scss';

const useStyles1 = makeStyles(ToastMessageStyle1);
const useStyles2 = makeStyles(ToastMessageStyle2);

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span style={{display: "inline-flex"}}>
          <Regionmesg />
          <span id="client-snackbar" className={classes.message}>
            {message}
          </span>
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,

      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default function ToastMessages(props) {
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: props.toastVPosition,
          horizontal: props.toastHPosition,
        }}
        open={props.statusMessage}
        autoHideDuration={2000}
        onClose={props.close}
      >
        <MySnackbarContentWrapper
          onClose={props.close}
          variant={props.variant}
          message={props.message}
        />
      </Snackbar>
    </div>
  );
}
