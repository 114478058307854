import React, {memo} from 'react';
import './AdminUsersGrid.css';
import Table from '../Table/Table';
import {DELETEADMIN,DELETEUSER,DELETEADMINDIALOG,DELETEUSERDIALOG} from '../../../Common/Constants/constants';

const AdminUsersGrid = (props) => {
    const {onContentChange,adminData,groupId,initialRender,gridType,type}= props;
    const dialogHead = (gridType === 0) ? DELETEADMIN : DELETEUSER;
    const dialogContent = (gridType === 0) ? DELETEADMINDIALOG : DELETEUSERDIALOG;
    return (
      <div className="App">
        <Table 
          onContentChange={onContentChange}
          items={adminData}
          groupId={groupId}
          initialRender={initialRender}
          gridType={gridType}
          type={type}
          dialogContent={dialogContent}
          dialogHead={dialogHead} />
      </div>
    );
}

export default memo(AdminUsersGrid);
