import React, { Component } from 'react';
import Dialog from "@material-ui/core/Dialog";
import { INVALIDHVACUSERTEXT, MIGRATIONWARNINGLOGOUTTEXT, LOGIN } from '../../Common/Constants/constants';
import { logout } from '../../Common/Utilities/utility';
import Timer from '../../Assests/Images/Timer.svg';
import './MigrationError/MigrationError.scss';

export default class InvalidHvacError extends Component {
    state = {
        seconds: 10,
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds } = this.state;

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            else {
              clearInterval(this.myInterval);
              logout();
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { seconds } = this.state;
        return (
            <div>
                <Dialog
                  PaperProps={{
                    style: {
                      minWidth: "50%",
                      padding: "34px",
                      alignItems: "center",
                      textAlign: "center"
                    },
                  }}
                  disableBackdropClick
                  disableEscapeKeyDown
                  open={true}
                >
                  <div className="MigrationTextContainer">{INVALIDHVACUSERTEXT}</div>
                  <div className="MigrationLogoutContainer">
                    {MIGRATIONWARNINGLOGOUTTEXT} 
                    <img src={Timer} className="MigrationErrorTimerImage"/>
                    {seconds}
                    <span className="MigrationMessageDivider"> / </span>
                    <button className="MigrationErrorButton" onClick={() => logout() }>
                      {LOGIN}
                    </button>
                  </div>                  
                </Dialog>
            </div>
        )
    }
}
