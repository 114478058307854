import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { SettingsDrawerStyles, APPGROUPTEXT, CONTACTADMINTEXT, APPSGROUPSNOACCESSTEXT, SAVETEXT, SAVEAPPLYALL, SAVEAPPGROUPS, APPLYALLHEADERLABEL, LOADINGTEXT, NOGROUPSFOUND, GETAPPSGROUPDATA, APPLYALLDESCRIPTIONLABEL, APPLYTEXT, GETGROUPS } from '../../../Common/Constants/constants';
import appConfig from '../../../Environment/environments';
import axiosInstance from '../Interceptor/interceptor';
import Typography from '@material-ui/core/Typography';
import ApplyAllGroups from './ApplyAllGroups';
import { encryptData } from '../../../Common/Utilities/utility';

const useStyles = SettingsDrawerStyles;

class ApplyAllGroupsDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      children: [],
      loading: true,
      isApplyAllSelectionChanged: false
    };

    this.showGroupsInApps = false;
    this.fetchedGroups = [];
    this.selectedGroups = [];
    this.deSelectedGroups = [];
    this.getRootGroups = this.getRootGroups.bind(this);
    this.saveSelectedGroups = this.saveSelectedGroups.bind(this);
    this.saveApplySettings = this.saveApplySettings.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.selectOrDeselectAllChildren = this.selectOrDeselectAllChildren.bind(this); 
  }

  saveSelectedGroups(shouldAdd, id, children, shouldAddChildren=false) {
    let index = this.selectedGroups.indexOf(id);
    let deSelectedIndex = this.deSelectedGroups.indexOf(id);
    if (shouldAdd) { 
      (index <= -1) && this.selectedGroups.push(id);
      (deSelectedIndex > -1 && index <= -1) && this.deSelectedGroups.splice(deSelectedIndex, 1);
      (shouldAdd && shouldAddChildren) && this.selectOrDeselectAllChildren(children, true);
    }
    else {
      if (index > -1) {
        this.selectedGroups.splice(index, 1);
        (deSelectedIndex <= -1) && this.deSelectedGroups.push(id);
        this.selectOrDeselectAllChildren(children, false);
      }
    }
    this.setState({
      isApplyAllSelectionChanged: (this.selectedGroups.length > 0)
    });
  }

  selectOrDeselectAllChildren(Data, shouldAdd, type='group') {
    Data.map((item) => {
      const hasChildren = (item.children && item.children.length > 0);
      let childIndex = this.selectedGroups.indexOf(item.value);
      let deSelectedIndex = this.deSelectedGroups.indexOf(item.value);
      if(shouldAdd && type === 'group') {        
        (childIndex <= -1) && this.selectedGroups.push(item.value);
        (deSelectedIndex > -1 && childIndex <= -1) && this.deSelectedGroups.splice(deSelectedIndex, 1);
      }
      else if(type === 'app') {
        (childIndex <= -1 && item.isSelected) && this.selectedGroups.push(item.value) && this.fetchedGroups.push(item.value);
      }
      else {
        (childIndex > -1) && this.selectedGroups.splice(childIndex, 1);
        (deSelectedIndex <= -1 && childIndex > -1) && this.deSelectedGroups.push(item.value);
      }
      if (hasChildren) {
        (type === 'app') ? this.selectOrDeselectAllChildren(item.children, item.isSelected, 'app') : this.selectOrDeselectAllChildren(item.children, shouldAdd)
      }
    })
  }

  showRootSettings() {
    return (
      this.state.children.map((ChildGroup, index) => (
        <ApplyAllGroups isChildrenDisplayed key={index} TreeLevel={0} groupData={ChildGroup} saveSelectedGroups={this.saveSelectedGroups} selectedGroups={this.selectedGroups}/>
      ))
    )
  }

  getGroupsData = () => {
    if (this.props.Id) {
      axiosInstance.get(appConfig.api.development + GETAPPSGROUPDATA + encryptData(this.props.Id).toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        let isDataAvailable = (res.data.groupAppInfoCustomModel === null || res.data.groupAppInfoCustomModel === undefined || res.data.groupAppInfoCustomModel === '');
        !isDataAvailable && this.selectOrDeselectAllChildren(res.data.groupAppInfoCustomModel, true, 'app');
        this.showGroupsInApps = res.data.isPermissionRequired;
        this.setState({
          children: (!this.showGroupsInApps) ? ( (isDataAvailable) ? [] : res.data.groupAppInfoCustomModel) : [],
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        console.log("error:", error);
      })
    }
  }

  getRootGroups(groupId) {
    axiosInstance.get(appConfig.api.development + GETGROUPS + encryptData(groupId).toString(), {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
        .then(res => {
          this.setState({
            children: res.data,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          console.log("error:", error);
        })
  }
  
  saveGroups = () => {
    if (this.props.Id) {
      let addedGroups = [];
      for (let i=0;i< this.selectedGroups.length;i++) {
        (this.fetchedGroups.indexOf(this.selectedGroups[i]) <= -1) && addedGroups.push(this.selectedGroups[i]);
      }
      const appObj = {
        appId: this.props.Id,
        deletedGroups: this.deSelectedGroups,
        addedGroups: addedGroups
      };
      const updatedAppGroupsObj = JSON.stringify(appObj);
      axiosInstance.post(appConfig.api.development + SAVEAPPGROUPS, updatedAppGroupsObj, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
        .then(res => {
          this.fetchedGroups = [];
          this.selectedGroups = [];
          this.deSelectedGroups = [];
          this.setState({
            children: [],
            loading: true
          });
          this.props.onSave(true);
        })
        .catch(error => {
          console.log("error:", error);
          this.props.onSave(false);
        });
    }
  }

  saveApplySettings() {
    const obj = {
      GroupId: this.props.savedAppSettings.GroupId,
      Apps: this.props.savedAppSettings.Apps,
      childGroupIds: this.selectedGroups
    };
    const updatedObj = JSON.stringify(obj);

    axiosInstance.post(appConfig.api.development + SAVEAPPLYALL, updatedObj, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        this.selectedGroups = [];
        this.setState({
          children: [],
          loading: true,
          isApplyAllSelectionChanged: false
        });
        this.props.onSave(true);
      })
      .catch(error => {
        console.log("error:", error);
        this.props.onSave(false);
      });

  }

  closeDialog() {
    this.selectedGroups = [];
    this.props.onClose();
    this.setState({
      children: [],
      loading: true,
      isApplyAllSelectionChanged: false
    });
  }

  showGroupsInDrawer = () => {
    const { classes } = this.props;
    if (this.state.children.length <= 0 && this.props.open && this.state.loading) {
        this.getRootGroups(this.props.groupId);
    }

    return (
      <Drawer anchor="right" open={this.props.open} onClose={this.closeDialog}
        ModalProps={{ disableBackdropClick: false, disableEscapeKeyDown: false }}
        PaperProps={{
          classes: {
            root: classes.paperRoot
          }
        }}
      >
        {this.showGroups(classes, 'group')}
      </Drawer>
    )
  }

  showGroups = (classes, type) => {
    if (type === 'group')
    {
      return <div className={classes.list} role="presentation">
        <div className={classes.editTitle}>
          <p className={classes.editGroupTitle}>{APPLYALLHEADERLABEL}
            <span className={classes.closeIcon} onClick={this.closeDialog}>
              <EditClose></EditClose>
            </span>
          </p>
        </div>
        {(this.state.children.length > 0) ?
            <div className="applyAllRootContainer">
                <Typography classes={{ root: classes.disclaimer }}>
                  {APPLYALLDESCRIPTIONLABEL}
                </Typography>
                <div className={classes.rootSettings}>{this.showRootSettings()}</div>
            </div> : 
            <p className={classes.loadingTitle}>
                {(this.state.loading) ? LOADINGTEXT : NOGROUPSFOUND}
            </p>
        }
        {(this.state.isApplyAllSelectionChanged) ? 
        <div className={classes.settingsSaveButton}>
            <Button variant="contained" onClickCapture={this.saveApplySettings} disableRipple className={classNames(classes.margin, classes.updatebutton)}>{APPLYTEXT}</Button>
        </div> : ''
        }
        </div>
      }
      else {
        return <>
        <div className="groupsHeaderContainer">
        { (!this.showGroupsInApps && (this.state.loading === false)) && (
          <>
          <div className={classes.appAdminText}>{APPGROUPTEXT}</div>
          <div className={classes.appAdminButtonContainer}>
            <Button variant="contained" disabled={false} id="ManageUsersInviteButton" disableRipple classes={{ root: classes.userAppButtonRoot }} onClick={() => this.saveGroups()} >
                  {SAVETEXT}
              </Button>
          </div>
          </>)
        }
        </div>
        <div className={classes.appContainerList} role="presentation">
        {(!this.showGroupsInApps) ?
            (this.state.children.length > 0) ?
              <div className="applyAllRootContainer">
                  <div className={classes.rootSettings}>{this.showRootSettings()}</div>
              </div> : 
              <p className={classes.loadingAppTitle}>
                  {(this.state.loading) ? LOADINGTEXT : NOGROUPSFOUND}
              </p>
            :
            <>
              <p className={classes.loadingAppTitle}>
                  {APPSGROUPSNOACCESSTEXT}
              </p>
              <p className={classes.appsGroupsNoAccess}>
                  {CONTACTADMINTEXT}
              </p>
            </>
        }
        </div>
        </>
      }      
  }

  showGroupsWithoutDrawer = () => {
    const { classes } = this.props;
    if (this.state.children.length <= 0 && this.state.loading) {
      this.getGroupsData();
    }
    
    return (
      this.showGroups(classes,'app')
    )
  }

  render() {
      return (
        (this.props.type === 'group') ? this.showGroupsInDrawer() : this.showGroupsWithoutDrawer()
      )
    }
}

export default withStyles(useStyles, { withTheme: true })(ApplyAllGroupsDrawer);