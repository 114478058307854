import React from 'react';
import { FormattedMessage as Culture } from 'react-intl';
import * as Msal from 'msal';
import appConfig from '../../Environment/environments';
import CryptoJS from 'crypto-js';
import axiosInstance from '../../Components/Shared/Interceptor/interceptor';
import {logout as authLogout} from '@CCR/reactauthwrapper';

export const translation = (id) => {
    return <Culture id={id} />;
}

export const injectIntlTranslation = (intl, id) => {
    return intl.formatMessage({
        id: id
    });
}
export const getDataTranslated = (data) => {
    data.forEach(element => {
        if (element.lastSigned != null) {
            var lastSigned = new Date(element.lastSigned);
            element.lastSigned = lastSigned.toLocaleString();
        }
    });
    return data;
}
export const encryptData = (data) => {
    var key = CryptoJS.enc.Utf8.parse(appConfig.api.encryption.Key);
    var iv = {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(appConfig.api.encryption.IV),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };

    return encodeURIComponent(CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, iv));
}

export const updateLogoutTime = async () => {
    try {
        const updateLogtimeResult = await axiosInstance.put(`${appConfig.api.development}/api/users/logout`)
        if (updateLogtimeResult.data) {
            // logout()
            authLogout(appConfig.api.loginConfig);
        }
    }
    catch (error) { }
}

export const logout = () => {
    localStorage.clear();
    const msalConfig = {
        auth: {
            clientId: appConfig.api.clientID,
            authority: appConfig.api.authority,
            postLogoutRedirectUri: appConfig.api.postLogoutRedirectUri,
            navigateToLoginRequestUrl: false,
            validateAuthority: false
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    };
    const userAgentApplication = new Msal.UserAgentApplication(msalConfig);
    userAgentApplication.handleRedirectCallback((error, response) => {
    });
    userAgentApplication.logout();
}