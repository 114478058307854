import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ManageGroupStyles, DELETEROOTPERMISSION, DELETECHILDPERMISSION, PERMISSIONSUNIQUEFAIL, FETCHPERMISSIONS, CREATEPERMISSION, PERMISSIONSSAVEFAIL, PERMISSIONSREMOVEFAIL, PERMISSIONSFETCHFAIL, PERMISSIONSSAVESUCCESS, EditGroupStyles, APPPERMISSIONNAME, PERMISSIONSTAB, LOADINGTEXT, NOPERMISSIONSTEXT, ADDPERMISSIONSTEXT, FIELDREQ, GROUPLENGTH, ADDCHILDPERMISSIONS } from '../../../Common/Constants/constants';
import '../../../Assests/Styles/Styles.scss';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import '../../Groups/manageUsers/manageUsers.scss';
import Button from '@material-ui/core/Button';
import CustomTextField from '../../Groups/CustomTextField';
import SaveIcon from '@material-ui/icons/Save';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import { encryptData } from '../../../Common/Utilities/utility';

const styles = ManageGroupStyles;
const permissionCardStyles = EditGroupStyles;

class PermissionsTab extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      statusMessage: false,
      message: "",
      toastVPosition: 'top',
      toastHPosition: 'center',
      messageVariant: '',
      toBeAddedPermissionCards: [],
      permissionCards: [],
      loading: true
    };
  }

  componentDidMount() {
    (this.props.Id) && this.getPermissions(this.props.Id);
  }
  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      statusMessage: false
    });
  }

  createPermissionCard = (level, field, index, showSave=false, type='save') => {
      let { classes } = this.props;
      let Indentation = 8;
      let PermisssionIndentation = (Indentation * level);
      let groupNameTextField = classes.appPermissionsTextField;
      classes = { ...classes, groupNameTextField}
      let endAdornment = {endAdornment: (
        <InputAdornment position="end">
          {(showSave && field.changed && (field.error === "")) ? <SaveIcon className={classes.permissionSaveIcon} onClick={() => this.savePermission(field, level)} /> : ""}
          <CloseIcon className={classes.permissionCloseIcon} onClick={() => this.removePermission(level, field, index ,type)} />
        </InputAdornment>
      )};
      return (
       <>
        <div key={index} style={{ marginLeft: PermisssionIndentation + "px" }} className={classes.permissionsFieldContainer}>
          <span className={classes.requiredAsterik}>*</span>
          <span className={classes.groupName}>
            <CustomTextField disabled={field.disabled} type="app" endAdornment={endAdornment} id={index} classes={classes} label={field.fieldName} name={field.value} nameError={field.error} nameChangeHandler={(e) => this.fieldChangeHandler(e.target.value, index, type, field)} />
            <div className={classes.errorText}>{field.error}</div>
          </span>
        </div>
        { (type ==='save') &&
          <div key={index} style={{ marginLeft: (PermisssionIndentation + Indentation) + "px" }} className={classes.permissionsSubFieldContainer}>
            <span className={classes.addSubPermission} onClick={() => this.addSubPermission(field.position, field.id, field.children)}>
              <SubdirectoryArrowRightIcon className={classes.addSubPermissionIcon} />
            </span>
            <span className={classes.addSubPermission} onClick={() => this.addSubPermission(field.position, field.id, field.children)}>{ADDCHILDPERMISSIONS}</span>
          </div>
        }
      </>
  )}

 crudOnCards = (obj, type='create', parentPos=null, ...args) => {
    for (var i in obj) {
      if (typeof obj[i] == 'object') {
      switch(type) {
        case 'create': 	let permission;
                        if ('id' in obj[i]) {
                          permission = obj[i];
                        }
                        else {
                          permission = {"fieldName": APPPERMISSIONNAME, "id": obj[i].settingId, "parentSettingId": obj[i].parentSettingId, "error": "", "value": obj[i].settingName, "changed": false, "originalValue": obj[i].settingName, "children": obj[i].children, "position": []};
                          let newPos = (parentPos === null) ? [] : parentPos.concat([]);
                          newPos = newPos.concat([parseInt(i)]);
                          permission.position = (parentPos === null) ? [parseInt(i)] : newPos;
                        }
                        if (args && args[0][i] && (typeof args[0][i].id !== 'string')) {
                          if ((obj[i].settingId === args[0][i].id) && (args[0][i].changed === true)) {
                            permission.error = args[0][i].error;
                            permission.value = args[0][i].value;
                            permission.changed = args[0][i].changed;
                            permission.originalValue = args[0][i].originalValue;
                          }
                          obj[i] = permission;
                          const children = args[0][i].children.filter((data) => {
                            return (typeof data.id === 'string');
                          });
                          (children.length > 0) && ( obj[i].children = obj[i].children.concat(children));
                        }
                        else {
                          obj[i] = permission;
                        }                
                        obj[i].children = (obj[i] && obj[i].children && obj[i].children.length > 0 ) ? this.crudOnCards(obj[i].children, 'create', permission.position, (args[0][i]) ? args[0][i].children : []) : [];
                        break;

        case 'update': 	if (obj[i].id === args[0]) {
                          if (args[2]) {
                            obj[i].changed = false;
                            obj[i].originalValue = args[1];
                            obj[i].value = args[1];
                            obj[i].error = "";
                          }
                          else {
                            obj[i].changed = (obj[i].originalValue !== args[1]) ? true : false;
                            obj[i].value = args[1];
                            obj[i].error = ((obj[i].value == "") ? FIELDREQ : (obj[i].value.length > 100 ? GROUPLENGTH : ""));
                          }
                        }
                        else {
                        (obj[i].children.length > 0 ) && this.crudOnCards(obj[i].children, 'update', null, args[0], args[1], args[2]);
                        }
                        break;

        case 'subAdd':	if (obj[i].id === args[0]) {
                          obj[i].children.push(args[1]);
                        }
                        else {
                        (obj[i].children.length > 0 ) && this.crudOnCards(obj[i].children, 'subAdd', null, args[0], args[1]);
                        }
                        break;

        default: 	if (obj[i].id === args[0]) {
                  const children = obj[i].children.filter((data) => {
                    return data.id !== args[1];
                  });
                  obj[i].children = children;
                  }
                  else {
                  (obj[i].children.length > 0 ) && this.crudOnCards(obj[i].children, 'remove', null, args[0], args[1]);
                  }
                  break;
        }
      }
    }

    if(type === 'create') {
      return obj;
    }
    else {
      this.setState({
        permissionCards: obj
      });
    }
}

  getPermissions = (appId) => {
    if (appId) {
      axiosInstance.get(`${appConfig.api.development}${FETCHPERMISSIONS}${encryptData(appId)}`, 
        {
          method: 'GET',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
          let permissionCards = this.crudOnCards(res.data, 'create', null, this.state.permissionCards);
          this.setState({
            statusMessage: false,
            message: "",
            messageVariant: "",
            loading: false,
            permissionCards: permissionCards        
          });
      })
      .catch((e) => {
        this.setState({
          statusMessage: true,
          message: PERMISSIONSFETCHFAIL,
          messageVariant: "error",
          loading: false
        });
      });
    }
  }

  addSubPermission = (position, parentSettingId, children) => {
    let cards = this.state.permissionCards;
    let newPosition = position.concat([children.length]);
    let tempId = ('new' + JSON.stringify(newPosition));
    let permission = {"fieldName": APPPERMISSIONNAME, "id": tempId, "parentSettingId": parentSettingId, "error": "", "value": null, "changed": false, "originalValue": null, "children": [], "position": newPosition};
    
    if (position.length > 1) {
      this.crudOnCards(this.state.permissionCards, 'subAdd', null, parentSettingId, permission);
    }
    else {
      cards[position[0]].children.push(permission);
      this.setState({
          permissionCards: cards
      })
    }
  }

  savePermission = (field, level) => {
    if (this.props.Id) {
      let obj = (typeof field.id !== 'string') ? {"SettingName": field.value} : (level > 0 ) ? {"ParentSettingId":(typeof field.id === 'string') ? field.parentSettingId : field.id, "AppId":0,"SettingName":field.value} : {"ParentSettingId":0,"AppId":this.props.Id,"SettingName":field.value};
      let request;
      if (typeof field.id === 'string') {
        request = axiosInstance.post(`${appConfig.api.development}${CREATEPERMISSION}`, JSON.stringify(obj), 
          {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      }
      else {
        request = axiosInstance.put(`${appConfig.api.development}${CREATEPERMISSION}/${field.id}`, JSON.stringify(obj), 
          {
            method: 'PUT',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      }
      request.then(res => {
        if (field.parentSettingId === null) {
          let toBeAddedPermissionCards = this.state.toBeAddedPermissionCards.filter((data) => {
            return data.id !== field.id;
          });
          this.setState({
            toBeAddedPermissionCards: toBeAddedPermissionCards,
            statusMessage: true,
            message: PERMISSIONSSAVESUCCESS,
            messageVariant: "success",
          }, () => {
            this.getPermissions(this.props.Id)
          })
        }
        else {
          this.setState({
            statusMessage: true,
            message: PERMISSIONSSAVESUCCESS,
            messageVariant: "success",
          }, () => {
            if (typeof field.id === 'string') {
              this.crudOnCards(this.state.permissionCards, 'remove', null, field.parentSettingId, field.id);
            }
            else {
              this.crudOnCards(this.state.permissionCards, 'update', null, field.id, field.value, true);
            }
              
            this.getPermissions(this.props.Id);
          })
        }        
      })
      .catch((e) => {
        let errorMessage = (e.response.data.message.indexOf("UniqueKey Constraints failed") > -1) ? PERMISSIONSUNIQUEFAIL : PERMISSIONSSAVEFAIL;
        this.setState({
          statusMessage: true,
          message: errorMessage,
          messageVariant: "error",
        });
      });
    }
  }

  removePermission = (level, field, index ,type) => {
    if (this.props.Id && type === 'save') {
      let URL = (level === 0) ? `${DELETEROOTPERMISSION}${encryptData(this.props.Id)}${'&settingId='}${encryptData(field.id)}` : (DELETECHILDPERMISSION + encryptData(field.id));
      axiosInstance.delete(`${appConfig.api.development}${URL}`, 
        {
          method: 'DELETE',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        this.getPermissions(this.props.Id)
      })
      .catch((e) => {
        this.setState({
          statusMessage: true,
          message: PERMISSIONSREMOVEFAIL,
          messageVariant: "error",
        });
      });
    }
    else if(this.props.Id) {
      if (field.parentSettingId === null) {
        const toBeAddedPermissionCards = this.state.toBeAddedPermissionCards.filter((data, entryIndex) => {
          return entryIndex !== index;
        });
        this.setState({
          toBeAddedPermissionCards: toBeAddedPermissionCards
        })
      }
      else {
        this.crudOnCards(this.state.permissionCards, 'remove', null, field.parentSettingId, field.id);
      }
    }
  }

  showPermissions = (level, field, index, type='save') => {
    return (
      <>
        { this.createPermissionCard(level, field, index, field.changed, type) }
        { (field && field.children && (field.children.length > 0)) && field.children.map((field, index) => {
            return this.showPermissions((level+1), field, index, ((field.originalValue === null) ? 'add': 'save'))    
          })
        }
      </>
  );}

  fieldChangeHandler = (targetValue, index, type, field) => {
    if ((type === 'add') && (field.position === null)) 
    {
      let fieldData = this.state.toBeAddedPermissionCards;
      fieldData[index].changed = (fieldData[index].originalValue !== targetValue) ? true : false;
      fieldData[index].value = targetValue;
      fieldData[index].error = ((fieldData[index].value == "") ? FIELDREQ : (fieldData[index].value.length > 100 ? GROUPLENGTH : ""));
      this.setState({toBeAddedPermissionCards: fieldData});
    }
    else if(field.position.length === 1) { 
      let rootCardData = this.state.permissionCards;
      rootCardData[index].changed = (rootCardData[index].originalValue !== targetValue) ? true : false;
      rootCardData[index].value = targetValue;
      rootCardData[index].error = ((rootCardData[index].value == "") ? FIELDREQ : (rootCardData[index].value.length > 100 ? GROUPLENGTH : ""));
      this.setState({permissionCards: rootCardData});
    }
    else{
      this.crudOnCards(this.state.permissionCards, 'update', null, field.id, targetValue, false);
    }
  }

  addPermission = () => {
    let tempId = ('new' + this.state.toBeAddedPermissionCards.length);
    let newPermission = {"fieldName": APPPERMISSIONNAME, "id": tempId, "parentSettingId": null, "error": "", "value": '', "changed": false, "originalValue": null, "children": [], "position": null};
    let toBeAddedPerms = this.state.toBeAddedPermissionCards;
    toBeAddedPerms.push(newPermission);
    this.setState({ toBeAddedPermissionCards: toBeAddedPerms });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className="ManageUsersHeader">
          <ToastMessages
            statusMessage={this.state.statusMessage}
            message={this.state.message}
            variant={this.state.messageVariant}
            toastHPosition={this.state.toastHPosition}
            toastVPosition={this.state.toastVPosition}
            close={this.handleToast.bind(this)}
          />
        </div>
        <div className="PermissionsHeader">
          <div className={classes.appAdminText}><strong>{this.props.appName}</strong><span className={classes.spanPermissionText}>{PERMISSIONSTAB}</span></div>
          <div className={classes.appAdminButtonContainer}>
            <Button variant="contained" disabled={false} id="ManageUsersInviteButton" disableRipple classes={{ root: classes.userAppButtonRoot }} onClick={() => this.addPermission()} >
                  {ADDPERMISSIONSTEXT}
              </Button>
          </div>
        </div>
        <div className="PermisssionsContainer">
        {(this.state.toBeAddedPermissionCards.length > 0) && this.state.toBeAddedPermissionCards.map((field, index) => {
            return this.createPermissionCard(0, field, index, true, 'add')    
          })
        }
        {(this.state.permissionCards.length > 0) && this.state.permissionCards.map((field, index) => {
            return this.showPermissions(0, field, index)    
          })
        }
        {((this.state.toBeAddedPermissionCards.length + this.state.permissionCards.length) <= 0) &&
          <p className={classes.noPermissions}>
                {(this.state.loading) ? LOADINGTEXT : NOPERMISSIONSTEXT}
          </p>
        }
        </div>
      </div>
    );}
}

PermissionsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({...permissionCardStyles(theme), ...styles(theme)}), { withTheme: true })(PermissionsTab);
