import React, {memo} from "react";
import {ResponsivePie} from '@nivo/pie';
import Avatar from '@material-ui/core/Avatar';
import {getColor,strings} from "../InsightsHelper";
import userAuthStyles from "../UserAuthStyles";
import {USERS_LABEL} from '../../../../Common/Constants/constants';
import clsx from 'clsx';

const UserAuthPie = ({authStats}) => {
    const {chartData, totalUserCount} = authStats;
    const classes = userAuthStyles();
    const {avatarFallback,userCount,individualUsersAvatar,individualUsersInfoDiv,individualUsersInfoContainer,pieRoot,totalUsersh,totalUsersDiv,userAuthPie,usersInfoContainer} = classes;
    return (
    <div className={pieRoot}>
        <div id="userAuthPie" className={userAuthPie}>
            <ResponsivePie
                data={chartData}
                innerRadius={0.65}
                padAngle={0.7}
                cornerRadius={0}
                colors={getColor}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableRadialLabels={false}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                enableSlicesLabels={false}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                isInteractive={false}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[
                    {
                        match: {
                            id: 'ruby'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'c'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'go'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'python'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'scala'
                        },
                        id: 'lines'
                    },
                    {
                        match: {
                            id: 'lisp'
                        },
                        id: 'lines'
                    },
                    {
                        match: {
                            id: 'elixir'
                        },
                        id: 'lines'
                    },
                    {
                        match: {
                            id: 'javascript'
                        },
                        id: 'lines'
                    }
                ]}
            />
        </div>
        <div className={usersInfoContainer}>
            <div className={totalUsersDiv}>
                <h1 className={totalUsersh}>{totalUserCount} {USERS_LABEL}</h1>
            </div>
            <div className={individualUsersInfoContainer}>
                { !!chartData && chartData.map(dataItem => {
                    const {id,value}=dataItem;
                    return (
                        <div id={id} className={individualUsersInfoDiv}>
                            <Avatar classes={{fallback: avatarFallback}} className={clsx(classes[id],individualUsersAvatar)} />
                            {!!totalUserCount && <span className={userCount}>{value}</span>}
                            {strings[id]}
                        </div>
                )})}
            </div>
        </div>
    </div>
)}

export default memo(UserAuthPie);