import React, {memo} from "react";
import { ResponsiveBar } from '@nivo/bar';
import userAuthStyles from "../UserAuthStyles";
import {getChartColor} from "../InsightsHelper";
import { injectIntlTranslation } from '../../../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";
import {USERS_LABEL,GROUP_TEXT,USERS_CHILD_GROUPS, USERS_PER_GROUP,
    INCLUDES} from '../../../../Common/Constants/constants';

const UserAuthChart = ({groupStats,intl}) => {
    const {groupsStatData=[], totalGroups, chartGroupNames, groupStatIndexBy} = groupStats;
    const {userTooltipSpan,userTooltipContainer,userAuthBar,chartRoot,totalUsersh,totalGroupsDiv,usersInfoContainer} = userAuthStyles();
    const groupsStatDataLength = groupsStatData.length;
    const UserChartTooltip = (id, value) => (
        <div className={userTooltipContainer}>
            <span className={userTooltipSpan}>{GROUP_TEXT} {chartGroupNames[id]}</span>
            <h1 className={totalUsersh}>{Math.floor(value)} {USERS_LABEL}</h1>
        </div>
    )
    const childGroupsText = () =>
        <>
            {` (${injectIntlTranslation(intl, INCLUDES)} ${totalGroups} ${injectIntlTranslation(intl, USERS_CHILD_GROUPS)})`}
        </>
    return (
        <>
            { !!groupsStatDataLength &&
                <div className={chartRoot}>
                    <div className={usersInfoContainer}>
                        <div className={totalGroupsDiv}>
                            <h1 className={totalUsersh}>
                                {USERS_PER_GROUP} 
                                {!!totalGroups && childGroupsText()}
                            </h1>
                        </div>
                    </div>
                    <div id="UserAuthChart" className={userAuthBar}>
                        <ResponsiveBar
                            data={groupsStatData}
                            keys={Array.from({ length: groupsStatDataLength }, (_, i) => i)}
                            indexBy={groupStatIndexBy}
                            margin={{ top: 10, right: 10, bottom: 10, left: 40 }}
                            padding={0.1}
                            colors={getChartColor}
                            theme={{
                                tooltip: {
                                    container: {
                                        padding: 0,
                                    },
                                },
                            }}
                            tooltip={({ id, value, color }) => UserChartTooltip(id, value, color)}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'fries'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'sandwich'
                                    },
                                    id: 'lines'
                                }
                            ]}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={{
                                tickSize: 0,
                                tickPadding: 0,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -43,
                                format: axisValue => Math.floor(axisValue) === axisValue && axisValue
                            }}
                            enableGridY={false}
                            enableLabel={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            legends={[]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                    </div>
                </div>
            }
        </>
)}

UserAuthChart.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(memo(UserAuthChart));