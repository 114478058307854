import { makeStyles } from '@material-ui/core/styles';

const userAuthStyles = makeStyles((theme) => ({
    autheticated: {
        backgroundColor: '#33ca80',
    },
    notActive: {
        backgroundColor: '#d73a49',
    },
    pending: {
        backgroundColor: 'yellow',
    },
    avatarFallback: {
        display: "none"
    },
    userTooltipSpan:{
        fontWeight:"500"
    },
    userTooltipContainer:{
        display: "flex",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        flexDirection: "column",
        padding: "10px",
        borderTop: "6px solid #33ca80"
    },
    userAuthBar: {
        height: "175px",
        width: "100%"
    },
    totalGroupsDiv: {
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column"
    },
    userStatsCount: {
        marginRight: "3px",
        fontSize: "15px" 
    },
    userCount: {
        marginRight: "3px"
    },
    chartRoot: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        padding: "18px 13px",
        borderRadius: "2px",
        marginTop: "20px"
    },
    pieRoot: {
        display: "flex",
        backgroundColor: "white",
        padding: "18px 13px",
        borderRadius: "2px",
        marginTop: "20px"
    },
    userStatsRoot: {
        display: "flex",
        backgroundColor: "#f7f7f7",
        padding: "18px 13px",
        borderRadius: "2px",
        marginTop: "20px"
    },
    userAuthPie: {
        height: "75px",
        width: "75px"
    },
    usersInfoContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: "22px",
        width: "100%"
    },
    totalUsersDiv: {
        marginBottom: "9px"
    },
    totalUsersh: {
        margin: "0px"
    },
    individualUsersInfoContainer: {
        display:'flex',
        flexDirection:"row",
        [theme.breakpoints.down('sm')]: {
            flexDirection:"column",
        }
    },
    individualUsersInfoDiv: {
        display:'flex',
        flexDirection:"row",
        marginRight:"9px",
        [theme.breakpoints.down('sm')]: {
            marginBottom:"10px",
        }
    },
    usersStatsInfoDiv: {
        display:'flex',
        flexDirection:"column",
        width: "127px"
    },
    individualUsersAvatar: {
        height:"15px",
        width:"15px",
        marginRight:"5px"
    },
    statsGrid: {
        justifyContent: 'unset'
    }
}));

export default userAuthStyles;