import { LangOpt } from '../../Common/Utilities/languagesutils';
import appConfig from '../../Environment/environments';
import axiosInstance from '../../Components/Shared/Interceptor/interceptor';
import { LOCALE_SET_START, GETUSERLANGUAGE, SETUSERLANGUAGE, LOCALE_SET_FULFILLED, LOCALE_SET_FAILED } from '../../Common/Constants/constants';
import { encryptData } from '../../Common/Utilities/utility';

export const getLocale = () => (dispatch) => {
    axiosInstance.get(`${appConfig.api.development}${GETUSERLANGUAGE}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then(res => {
        let lang;
        LangOpt.forEach(opt => { 
            if(opt.fullLangCode.toUpperCase() === res.data.toUpperCase()){
                lang = opt.lang;
                return;
            }
        });
        dispatch({
            type: LOCALE_SET_FULFILLED,
            data: { lang: lang, Language: res.data }
        });
    }).catch(err => {
        dispatch({
            type: LOCALE_SET_FAILED,
            error: err
        });
    });
}

export const UserPerference = (opt) => (dispatch) => {
    dispatch({ type: LOCALE_SET_START });
    axiosInstance({
        url: `${appConfig.api.development}${SETUSERLANGUAGE}${encryptData(opt.fullLangCode).toString()}`,
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then(result => {
            dispatch({
                type: LOCALE_SET_FULFILLED,
                data: {lang: opt.lang}
            });
        }).catch(err => {
            dispatch({
                type: LOCALE_SET_FAILED,
                error: err
            });
        });
};

