import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ManageGroupStyles,UNABLE_TO_ADD_USER,SEND_HVAC_INVITATION_APP,HVAC_INVALID_MESSAGE,
  HVAC_INACTIVE_MESSAGE,ADDADMIN,USERDETAIL,SPECIFYONLYONEFIELDERROR,SENDINVITATIONAPP,
  USERADDEDTOAPP,APPUSERALREADYEXISTERROR,APPADMINTEXT,APPADMINDATA,INVALID_DOMAIN_ERROR } from '../../../Common/Constants/constants';
import AdminUsersGrid from '../../Shared/AdminUsersGrid/AdminUsersGrid';
import UserInvite from '../../Shared/userInvite/userInvite';
import '../../../Assests/Styles/Styles.scss';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import '../../Groups/manageUsers/manageUsers.scss';
import { encryptData, injectIntlTranslation,getDataTranslated } from '../../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";
import Users from '../../../Common/Services/Users';
var userInstance = new Users();

const styles = ManageGroupStyles;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: "0 24px" }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


class AdminTab extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      label: ADDADMIN,
      right: false,
      groupId: this.props.groupId,
      adminData: [],
      initialRender: false,
      statusMessage: false,
      message: "",
      toastVPosition: 'top',
      toastHPosition: 'center',
      messageVariant: '',
      userType: 1
    };

    this.openInviteUserDrawer = this.openInviteUserDrawer.bind(this);
  }
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.getAllAdminData();
    this.getUserType();
  }

  getUserType = () => {
    var emailid = userInstance.getUserEmail();
    axiosInstance.get(`${appConfig.api.development}${USERDETAIL}${encryptData(emailid).toString()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        var userdata = res.data;
        this.setState({ userType: userdata.userType });
      })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  getAllAdminData = () => {
    if (this.props.Id) {
      axiosInstance.get(appConfig.api.development + APPADMINDATA + encryptData(this.props.Id).toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (this._isMounted) {
            this.setState({
              adminData: getDataTranslated(response.data),
              initialRender: true
            });
            
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  }

  onInviteButtonHandler(updatedObj, isBothUserTypesSelected, isHvac=false) {
    if (isBothUserTypesSelected) {
      this.setState({
        statusMessage: true,
        message: SPECIFYONLYONEFIELDERROR,
        messageVariant: "error"
      })
    }
    else {
      const obj = isHvac ? updatedObj : JSON.parse(updatedObj);
      let dataObj = {};
      let url = "";
      if (obj.userType === 2) {
        url = SEND_HVAC_INVITATION_APP;
        dataObj = {
          appId: obj.groupId,
          countryCode: "",
          email: "",
          hvacUserName: obj.hvacUserName,
          isCarCGL: obj.isCarCGL,
          isManager: obj.isManager,
          name: "",
          upn: "",
          userType: obj.userType
        }        
      }
      else {
        url = SENDINVITATIONAPP;
        dataObj = {
          appId: obj.groupId,
          isManager: obj.isManager,
          userType: obj.userType,
          email: obj.email,
          name: obj.name,
          UPN: obj.UPN
        };
        if (obj.isCarCGL) {
          dataObj.isCarCGL = obj.isCarCGL;
        }
      }
      axiosInstance.post(appConfig.api.development + url, JSON.stringify(dataObj), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res) {
          this.setState({
            statusMessage: true,
            message: USERADDEDTOAPP,
            messageVariant: "success",
            right: false
          })
        }
        this.getAllAdminData();
        this.openInviteUserDrawer('right', this.state.right)
      })
      .catch(error => {
        if (error.response) {
          let errorMessage = APPUSERALREADYEXISTERROR;
          if (error.response.data.message.indexOf("is inactive.") > -1) {
            errorMessage = (injectIntlTranslation(this.props.intl, HVAC_INACTIVE_MESSAGE)).replace('_HVACUSER_', "\"" + dataObj.hvacUserName + "\"");
          }
          else if (error.response.data.message.indexOf("is invalid.") > -1) {
            errorMessage = (injectIntlTranslation(this.props.intl, HVAC_INVALID_MESSAGE)).replace('_HVACUSER_', "\"" + dataObj.hvacUserName + "\"");
          }
          else if (error.response.data.message.indexOf("Invalid domain") > -1) {
            errorMessage = INVALID_DOMAIN_ERROR
          }
          this.setState({
            statusMessage: true,
            message: errorMessage,
            messageVariant: "error"
          })
        }
        else {
          this.setState({
            statusMessage: true,
            message: UNABLE_TO_ADD_USER,
            messageVariant: "error"
          })
        }
      })
    }
  }

  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      statusMessage: false
    });
  }

  openInviteUserDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ [side]: open,  });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
       <div className="ManageUsersHeader">
        <div className={classes.appAdminText}>{APPADMINTEXT}</div>
        <div className={classes.appAdminButtonContainer}>
            <Button variant="contained" id="ManageUsersInviteButton" disableRipple classes={{ root: classes.userAppButtonRoot }} onClick={this.openInviteUserDrawer('right', true)} >
                {this.state.label}
            </Button></div>
        </div>

        <TabContainer><AdminUsersGrid adminData={this.state.adminData} initialRender={this.state.initialRender} type="app" /></TabContainer>
         <UserInvite
          eventHandle={this.onInviteButtonHandler.bind(this)}
          historyid={this.props.history}
          open={this.state.right}
          onClose={this.openInviteUserDrawer('right', false)}
          ID={this.props.Id}
          loggedInUserType={this.state.userType}
          isManager={true}
        />
        <ToastMessages
          statusMessage={this.state.statusMessage}
          message={this.state.message}
          variant={this.state.messageVariant}
          toastHPosition={this.state.toastHPosition}
          toastVPosition={this.state.toastVPosition}
          close={this.handleToast.bind(this)}
        />
      </div>
    );
  }
}

AdminTab.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withStyles(styles, { withTheme: true })(AdminTab));
