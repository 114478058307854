import React, { Component } from 'react';
import BlackEdit from '../../Assests/Images/Edit.svg';
import downArrow from '../../Assests/Images/arrow.svg';
import Arrow from '../../Assests/Images/Caret-Right.svg';
import '../../Assests/Styles/Styles.scss';
import GroupService from './Groups.services';
import { Link } from 'react-router-dom';
import Globe from '../../Assests/Images/Globe.svg';
import ReactTooltip from 'react-tooltip'
import { LOADING } from '../../Common/Constants/constants';

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Children: [],
      groupdId: "",
      flag: false,
      isChildrenDisplayed: false,
      LoadingChildren: (this.props.expandedGroups.indexOf(this.props.Group.groupId) > -1) ? true :false,
    };
    (this.props.expandedGroups.indexOf(this.props.Group.groupId) > -1) && this.handleHeaderClick(this.props.Group.groupId, this.props.Group.groupCountIncludingChildren);
  }
  handleHeaderClick(id, userCountIncludingChildren) {
    if (!this.state.isChildrenDisplayed && userCountIncludingChildren > 0) {
      if (this.state.Children.length > 0) {
        this.setState({
          isChildrenDisplayed: !this.state.isChildrenDisplayed,
        }, () => {
          (this.state.isChildrenDisplayed) ? this.props.toBeExpandedGroups(true, id): this.props.toBeExpandedGroups(false, id);
        });
      } else {
        this.setState({
          LoadingChildren: true
        })
        GroupService.getGroupsById(id, (result) => {
          this.setState({
            Children: result,
            isChildrenDisplayed: !this.state.isChildrenDisplayed,
            LoadingChildren: !this.state.LoadingChildren
          });
          this.props.toBeExpandedGroups(true, id);
        })

        this.state.groupdId = id;
      }

    } else {
      this.setState({
        isChildrenDisplayed: !this.state.isChildrenDisplayed
      });
      this.props.toBeExpandedGroups(false, id);
    }

  }
  DisplayChildren(id) {
    if (this.state.Children && this.state.Children.length > 0 && this.state.isChildrenDisplayed && this.state.groupdId == id) {
      return (
        this.state.Children.map((ChildGroup, index) => (
          <Group key={index} TreeLevel={this.props.TreeLevel + 1} Group={ChildGroup} toBeExpandedGroups={this.props.toBeExpandedGroups} expandedGroups={this.props.expandedGroups} setEditGroupId={this.props.setEditGroupId}/>
        ))
      )
    } else {
      return null;
    }
  }
  GetTreeIcon(userCountIncludingChildren, id) {
    if (userCountIncludingChildren > 0 && this.state.isChildrenDisplayed || (this.props.expandedGroups.indexOf(this.props.Group.groupId) > -1))
      return <img className="ArrowIcon" src={downArrow} onClick={() => this.handleHeaderClick(id, this.props.Group.groupCountIncludingChildren)} />
    else if (userCountIncludingChildren > 0 && !this.state.isChildrenDisplayed) {
      return (
        <div className="NotOpenedArrayContainer" onClick={() => this.handleHeaderClick(id, this.props.Group.groupCountIncludingChildren)}>
          <img className="ArrowRightIcon" src={Arrow} />
        </div>
      )
    } else {
      return <img className="ArrowIcon" src={Globe} />
    }
  }
  DisplayLoadingBar() {
    if (this.state.LoadingChildren && (this.props.Group.groupCountIncludingChildren > 0)) {
      return <span>{LOADING}</span>
    } else {
      return null
    }
  }
  StyleChildrenNumberData(ChildrenNumber) {
    if (ChildrenNumber > 0)
      return ChildrenNumber
    else {
      return null
    }
  }

  render() {
    var Indentation = 16;
    if (this.props.TreeLevel == 0)
      Indentation = 0
    return (
      <div className="Groupli" style={{ paddingLeft: Indentation + "px" }}>
        <div className="Group">
          {this.GetTreeIcon(this.props.Group.groupCountIncludingChildren, this.props.Group.groupId)}
          <div onClick={() => this.handleHeaderClick(this.props.Group.groupId, this.props.Group.groupCountIncludingChildren)} className="ExpendedCell">{this.props.Group.groupName}</div>
          <div data-tip={ "Edit " + this.props.Group.groupName} className="ClickableColumn"><img className="GroupEdit" onClick={() => this.props.setEditGroupId(this.props.Group.groupId)} src={BlackEdit} xs={2} /></div>
          <ReactTooltip place="top" type="dark" effect="float" />
          <div className="HeaderIconColumn">{this.StyleChildrenNumberData(this.props.Group.groupCountIncludingChildren)}</div>
          <div className="HeaderIconColumn">{this.props.Group.adminCount}</div>
          <div className="HeaderIconColumn">{this.props.Group.userCountIncludingChildren}</div>
        </div>
        {this.DisplayLoadingBar()}
        {this.DisplayChildren(this.props.Group.groupId)}
      </div>
    )
  }
}

export default Group;