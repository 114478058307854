import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import APITreeView from './UIDocTree';
import CenteredGrid from './UIDocGrids';
import uiDocumentationStyles from "./UIDocumentationStyles";
import { AUTHTOKEN, GETVERB, POSTVERB, NODEONENAME, NODEONEDESC, NODETWONAME, NODETWODESC, NODETHREENAME, NODETHREEDESC, NODEFOURNAME, NODEFOURDESC, NODEFIVENAME, NODEFIVEDESC, NODESIXNAME, NODESIXDESC, APITREE, CLOSE } from '../../../../Common/Constants/constants';
function UIDocumentation(props) {
    const { uidoctitle, dialogContent, uidocumentationwrapper, uidocumentationwrapperleft, tree, uidocumentationheading, uidocumentationclose } = uiDocumentationStyles();
    const [desc, setDesc] = React.useState(NODEONEDESC);
    const [url, setUrl] = React.useState(null);
    const [verb, setVerb] = React.useState(null);
    const [body, setBody] = React.useState(null);
    const [value, setValue] = React.useState(0);
    const [auth, setAuth] = React.useState(0);
    const [nodeid, seNodeId] = React.useState("1");
    const data = [
        {
            id: '1',
            name: NODEONENAME,
            description: NODEONEDESC,
            children: [
                {
                    id: '2',
                    name: NODETWONAME,
                    description: NODETWODESC,
                    verb: GETVERB,
                    url: "{{BaseUrl}}/api/users/GetUserProfileAndPermissions?appurl={{appurl}}",
                    body: null,
                    auth: AUTHTOKEN,
                },
                {
                    id: '3',
                    name: NODETHREENAME,
                    description: NODETHREEDESC,
                    verb: POSTVERB,
                    url: "{{BaseUrl}}/api/users/GetAllUsersByPermissionName",
                    body: "{“Permission”:”AppName/Permssion/Settings”,”GroupId”:”id”,”GroupInfo”:true,”UserActivityInfo”: true}",
                    auth: AUTHTOKEN
                },
                {
                    id: "4",
                    name: NODEFOURNAME,
                    description: NODEFOURDESC,
                    verb: GETVERB,
                    url: "{{BaseUrl}}/api/users/GetUserDetails?userId={{userId}}",
                    auth: AUTHTOKEN
                },
                {
                    id: "5",
                    name: NODEFIVENAME,
                    description: NODEFIVEDESC,
                    verb: GETVERB,
                    url: "{{BaseUrl}}/api/group/id?Id={{groupId}}",
                    auth: AUTHTOKEN
                },
                {
                    id: "6",
                    name: NODESIXNAME,
                    description: NODESIXDESC,
                    verb: GETVERB,
                    url: "{{BaseUrl}}/api/users/UpdateUserLastSignedIn",
                    auth: AUTHTOKEN
                }
            ]
        }];
    const handleSelect = (node) => {
        setDesc(node.description);
        setUrl(node.url);
        setVerb(node.verb);
        setBody(node.body);
        seNodeId(node.id);
        setAuth(node.auth);
        if (node.id != "1") {
            setValue(0);
        }
    };
    const handleClose = () => {
        setDesc(NODEONEDESC);
        setUrl(null);
        setVerb(null);
        setBody(null);
        seNodeId("1");
        setAuth(null);
        props.onCloseEvent();
    };
    const handleTab = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={props.opennow}
            scroll={props.scrollType}
            onClose={props.onCloseEvent}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="responsive-dialog-title" className={uidoctitle}> {props.dialogHead}</DialogTitle>
            <DialogContent dividers={props.scrollType === 'paper'} className={dialogContent}>
                <div className={uidocumentationwrapper}>
                    <section className={uidocumentationwrapperleft}>
                        <Paper className={tree}>
                            <Typography className={uidocumentationheading}>{APITREE}</Typography>
                            <APITreeView data={data} handleSelect={handleSelect}/>
                        </Paper>
                    </section>
                    <CenteredGrid desc={desc} nodeid={nodeid} verb={verb} url={url} auth={auth} body={body} value={value} handleTab={handleTab} />
                </div>
            </DialogContent>
            <DialogActions id="dialogbutton">
                <Button onClick={handleClose} className={uidocumentationclose} color="primary" variant="outlined" autoFocus>
                    {CLOSE}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
UIDocumentation.propTypes = {
    fullScreen: PropTypes.bool.isRequired
};

export default (UIDocumentation);