import { makeStyles } from '@material-ui/core/styles';

const uiDocumentationStyles = makeStyles((theme) => ({

    uidocumentationbutton: {
        color: "#fff",
        width: "150px",
        height: "35px",
        padding: "0px 10px",
        fontSize: "1rem",
        fontFamily: ["Helvetica Neue", "Helvetica", "Arial", "sans-serif"].join(','),
        lineHeight: "12px",
        borderColor: "#0033ab",
        marginRight: "830px",
        marginLeft: "4px",
        borderRadius: "none",
        textTransform: "none",
        backgroundColor: "#0033ab",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#0033ab"
          }
    },
    dialogContent: {
        padding: '15px'
    },
    uidoctitle: {
        backgroundColor: "#F7F7F7",
        padding: "14px 24px 4px"
    },
    uidocumentationwrapper: {
        display: "flex",
        flexDirection: "row",
        minHeight: "400px",
        "column-gap": "15px"
    },
    uidocumentationwrapperleft: {
        width: "300px",
        display: "flex",
        flex: '1 1 100px'
    },
    tree: {
        padding: "10px",
        backgroundColor: '#F7F7F7',
        color: '#0033AB',
        width: '100%',
        "& MuiTreeItem-label": {
            padding: '5px'
        }
    },
    uidocumentationheading: {
        fontSize: "1.25rem",
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
        fontWeight: 300,
        lineHeight: 1.6,
        color: "#000000",
        "margin-block-start": 0,
        "margin-block-end": 0
    },
    uidocumentationpaper: {
        textAlign: "center",
        color: "#0033AB",
        backgroundColor: "#F7F7F7"
    },
    uidocumentationclose: {
        color: "#000000",
        fontFamily: ["Helvetica Neue", "Helvetica", "Arial", "sans-serif"].join(','),
        fontSize: "12px",
        textAlign: "center",
        border: "1px solid #0033AB",
        cursor: "pointer",
        minWidth: "64px",
        minHeight: "35px",
        boxSizing: "border-box",
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontWeight: 500,
        borderRadius: "4px",
        lineHeight: 1.75,
        marginTop: "9px",
        marginLeft: "860px"
    },
    uidocumentationwrapperright: {
        display: "flex",
        flexDirection: "column",
        "row-gap": "15px",
        height: "400px",
        overflowY: "auto",
        overflowX: "hidden"
    },
    paper: {
        maxWidth: 600,
        margin: `spacing(1px) auto`,
        padding: 'spacing(2)',
        backgroundColor: '#F7F7F7',
        color: '#0033AB',
        padding: '10px'
    },
    uidocumentationwrapperrightdescription: {
        display: "flex",
        flexDirection: "column",
    },
    uidocumentationwrapperrightmethod: {
        display: "flex",
        flexDirection: "column",
    },
    uidocumentationwrapperrightparams: {
        flex: "1 1 100px",
        display: "flex",
        flexDirection: "column",
    },
    small: {
        width: "3px",
        height: "3px",
    },
    table: {
        minWidth: 650,
    },
    tabpanel: {
        maxWidth: 600,
        minWidth: 600,
        backgroundColor: '#F7F7F7',
        color: '#0033AB',
        height: '100%'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    image: {
        width: "584px",
        height: "322px",
    },
    uidocumentationheading: {
        fontSize: "1.25rem",
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
        fontWeight: 300,
        lineHeight: 1.6,
        color: "#000000"
    },
    bodypanel: {
        overflow: 'hidden'
    },
    methodAvatar: {
        'font-size': '12px'
    },
    urlGrid: {
        display: 'flex',
        'align-items': 'center'
    },
    uidocumentationbody: {
        'word-break': 'break-all'
    },
    copySnippet: {
        'position': 'relative'
    },
    copyIcon: {
        'position': 'absolute',
        'right': '10px',
        'top': '10px'
    },
}));

export default uiDocumentationStyles;