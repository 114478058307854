import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import uiDocumentationStyles from "./UIDocumentationStyles";
import { DESCRIPTION, CSHARP, REACT, COPIED, COPYFAILED } from '../../../../Common/Constants/constants';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ToastMessages from '../../../Shared/Toast/ToastMessages';

export default function CenteredGrid(props) {
    const { uidocumentationwrapperright, uidocumentationwrapperrightdescription, paper, uidocumentationheading, image, img, uidocumentationwrapperrightmethod, methodAvatar, urlGrid, uidocumentationwrapperrightparams, tabpanel, bodypanel, uidocumentationbody, copyIcon, copySnippet } = uiDocumentationStyles();
    const [copied, setCopied] = React.useState(false);
    const [variant, setVariant] = React.useState('');
    const [copyMsg, setCopyMsg] = React.useState('');
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function handleToast(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
        setCopied(false);
    }

    const copyToClipBoard = async copyMe => {
        try {
          await navigator.clipboard.writeText(copyMe);
          setVariant("success");
          setCopyMsg(COPIED);
          setCopied(!copied);
        } catch (err) {
          setVariant("error");
          setCopyMsg(COPYFAILED);
          setCopied(!copied);
        }
    };

    return (
        <div className={uidocumentationwrapperright}>
            <div className={uidocumentationwrapperrightdescription}>
                <Paper className={paper}>
                    <Typography className={uidocumentationheading}>{DESCRIPTION}</Typography>
                    <Grid container wrap="nowrap" spacing={3}>
                        <Grid item xs>
                            <Typography>{props.desc}</Typography>
                            {props.nodeid == "1" &&
                                <div>
                                    <Tabs initialSelectedIndex={0} value={props.value} onChange={props.handleTab} indicatorColor="primary">
                                        <Tab label="c#" {...a11yProps(0)} />
                                        <Tab label="reactjs" {...a11yProps(1)} />
                                    </Tabs>
                                    <TabPanel value={props.value} index={0} className={bodypanel}>                                        
                                    <div className={copySnippet}>
                                        <ToastMessages statusMessage={copied}
                                        message={copyMsg}
                                        variant={variant}
                                        toastHPosition={'center'}
                                        toastVPosition={'top'}
                                        close={handleToast} />
                                        <FileCopyIcon className={copyIcon}  onClick={() => copyToClipBoard(CSHARP)} />
                                        <SyntaxHighlighter language="javascript" style={docco}>
                                            {CSHARP}
                                        </SyntaxHighlighter> 
                                    </div>
                                    </TabPanel>
                                    <TabPanel value={props.value} index={1} className={bodypanel}>
                                    <div className={copySnippet}>
                                        <ToastMessages statusMessage={copied}
                                        message={copyMsg}
                                        variant={variant}
                                        toastHPosition={'center'}
                                        toastVPosition={'top'}
                                        close={handleToast} />
                                        <FileCopyIcon className={copyIcon}  onClick={() => copyToClipBoard(REACT)} />
                                        <SyntaxHighlighter language="javascript" style={docco}>
                                            {REACT}
                                        </SyntaxHighlighter> 
                                    </div>
                                    </TabPanel>
                                </div>}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            {props.verb &&
                <div className={uidocumentationwrapperrightmethod}>
                    <Paper className={paper}>
                        <Grid container wrap="nowrap" spacing={3}>
                            <Grid item>
                                <Avatar variant="square" className={methodAvatar}>{props.verb}</Avatar>
                            </Grid>
                            <Grid item xs className={urlGrid}>
                                <Typography>{props.url}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            }
            {props.nodeid != "1" &&
                <div className={uidocumentationwrapperrightparams}>
                    <Paper className={tabpanel}>
                        <Tabs initialSelectedIndex={0} value={props.value} onChange={props.handleTab} indicatorColor="primary">
                            {props.auth && <Tab label="Authorization" {...a11yProps(0)} />}
                            {props.body && <Tab label="Body" {...a11yProps(1)} />}
                        </Tabs>
                        <TabPanel value={props.value} index={0}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Key</b></TableCell>
                                            <TableCell><b>Value</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Authorization
              </TableCell>
                                            <TableCell>{props.auth}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={props.value} index={1} className={bodypanel}>
                            <Typography className={uidocumentationbody}>
                                {props.body}
                            </Typography>
                        </TabPanel>
                    </Paper>
                </div>}
        </div>
    );
}