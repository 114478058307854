import React, { useState } from 'react';
import appConfig from '../../../Environment/environments';
import { AUTHORITYBASE, CLIENTID, CLIENTSCOPE, CLOSE, TENANT, AUTHORITYURL, UIDOCUMENTATION, DOCUMENTATION } from '../../../Common/Constants/constants';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Clipboard from '../../../Assests/Images/Clipboard.svg';
import UIDocumentation from './UIDocumentation/UIDocumentation';
import uiDocumentationStyles from "./UIDocumentation/UIDocumentationStyles";
export default function DeveloperTab() {
  const { uidocumentationbutton } = uiDocumentationStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let fields = [{ "fieldName": TENANT, "value": appConfig.api.tenant },
  { "fieldName": CLIENTID, "value": appConfig.api.clientID },
  { "fieldName": AUTHORITYBASE, "value": appConfig.api.authorityBase },
  { "fieldName": AUTHORITYURL, "value": appConfig.api.authorityURL },
  { "fieldName": CLIENTSCOPE, "value": appConfig.api.clientScope1 }];
  return (<div className="developerFieldsContainer">
    {fields.map((field) => {
      return <>
        <Typography component="div" className="developerField">
          {field.fieldName}
        </Typography>
        <Typography component="div" className="developerFieldValue">
          <img src={Clipboard} xs={2} />
          {field.value}
        </Typography>
      </>
    })}
    <Button className={uidocumentationbutton} onClick={handleClickOpen('paper')} >
      {UIDOCUMENTATION}
    </Button>
    <UIDocumentation dialogHead={DOCUMENTATION} opennow={open} scrollType={scroll} dialogButtonText={CLOSE} onCloseEvent={handleClose} />
  </div>);
}


